import { useAuthContext } from "auth/context";
import { gql } from "generated/graphql-codegen";
import { UserForAnalyticsFragment } from "generated/graphql-codegen/graphql";
import _ from "lodash";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { analytics } from "react-hooks/useAnalytics";
import {
  buildCommonIdentifyTraits,
  buildGroupOrgAttributes,
  trackProperties,
} from "shared/analytics";
import { captureSentryError } from "shared/errors/sentryError";
import routeForPathname from "utils/routing";
import useIntercom from "utils/useIntercom";

gql(`
fragment UserForAnalytics on User {
  id
  intercomHash
  currentUserMembership {
    id
    createdAt
    persona
    imageUrl
    name
    email
  }
  currentOrganization {
    ...AnalyticsMetadataOrganization
  }
}
`);

interface AnalyticsWrapperProps {
  children?: React.ReactNode;
  user?: UserForAnalyticsFragment | null;
}

function AnalyticsWrapper({ children, user }: AnalyticsWrapperProps) {
  const router = useRouter();
  const { highestRole } = useAuthContext();
  const { callIntercom } = useIntercom();

  useEffect(() => {
    if (user?.id) {
      callIntercom("update", {
        user_hash: user.intercomHash,
        user_id: user.id,
      });
    }
  }, [callIntercom, user?.id, user?.intercomHash]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const matchingRoute = routeForPathname(url);
      if (matchingRoute) {
        if (!matchingRoute.disableTrackPage) {
          analytics.page(
            matchingRoute.name,
            matchingRoute.customerSuccess
              ? trackProperties.isCustomerSuccess
              : undefined
          );
        }
      } else {
        captureSentryError(new Error(`no matching route: ${url}`), {
          title: "syncAndBeginPolling unable to find route",
        });
      }
    };
    if (user) {
      analytics.identify(
        user.id,
        buildCommonIdentifyTraits({
          user,
          highestRole,
          organization: user.currentOrganization,
        })
      );
      if (user.currentOrganization) {
        analytics.group(
          user.currentOrganization.id,
          // https://segment.com/docs/connections/spec/group/#traits
          buildGroupOrgAttributes(user.currentOrganization)
        );
      }
    }
    // If the router is already ready, it means we completed the route change
    // before running this identity call, so we should manually run a call
    // to analytics.page
    if (router.isReady) {
      const matchingRoute = routeForPathname(router.asPath);
      if (matchingRoute) {
        if (!matchingRoute.disableTrackPage) {
          analytics.page(matchingRoute.name);
        }
      } else {
        captureSentryError(
          new Error(
            `Unable to find a matching route for in our analytics mapping file for url. Go add one or if we don't want to track a page event for that route, add "disableTrackPage: true" to the mapped route (${router.asPath})`
          ),
          {
            title: "analytics unable to find route",
          }
        );
      }
    }
    // If data is defined, it means we've at least finished our graphql fetch,
    // which means we should now start tracking route changes
    if (!_.isUndefined(user)) {
      router.events.on("routeChangeComplete", handleRouteChange);
    }
    return () => router.events.off("routeChangeComplete", handleRouteChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <>{children}</>;
}

export default AnalyticsWrapper;
