// AUTOGENERATED FILE. DO NOT EDIT.
// Run 'yarn gen:ff-enum' to update.
enum FeatureFlags {
  ADD_OFFICE_EMAIL_TEMPLATE_VARIABLE = "add-office-email-template-variable",
  ADD_STAGES_BULK_ACTION = "add-stages-bulk-action",
  ADD_TO_CALENDAR = "add-to-calendar",
  ADHOC_MESSAGING_V_2 = "adhoc-messaging-v2",
  ADVANCED_RESCHEDULE_REQUIREMENTS = "advanced-reschedule-requirements",
  AI_SCHEDULING_SCENARIOS_V_2 = "ai-scheduling-scenarios-v-2",
  ALLOW_EXTERNAL_CC = "allow-external-cc",
  ALLOW_RESCHEDULING_CANCELLED_V_2 = "allow-rescheduling-cancelled-v-2",
  APOLLO_RATE_LIMITING = "apollo-rate-limiting",
  ATLAS_DIALOG_V_2 = "atlas-dialog-v2",
  ATSSYNC_PAGE_FETCH_DELAY = "atssync-page-fetch-delay",
  ATSSYNC_PAGE_SIZE = "atssync-page-size",
  ATTACH_EMAIL_CALENDAR_FILES = "attach-email-calendar-files",
  BOOKING_LINKS_V_2 = "booking-links-v-2",
  BRAND_KITS_EDIT_IN_JOBS_AND_GUIDES = "brand-kits-edit-in-jobs-and-guides",
  BRAND_KITS_IN_TEMPLATES_DISPLAY = "brand-kits-in-templates-display",
  BULL_MQ_1 = "bull-mq-1",
  BULL_MQ_2 = "bull-mq-2",
  BULL_MQ_MIGRATOR_BATCH_SIZE = "bull-mq-migrator-batch-size",
  BULL_MQ_MIGRATOR_DELAY_TIME = "bull-mq-migrator-delay-time",
  BULL_MQ_MIGRATOR_FILTER = "bull-mq-migrator-filter",
  CANCEL_STAFF_SCHEDULED_INTERVIEWS_BY_CANDIDATE = "cancel-staff-scheduled-interviews-by-candidate",
  CANCELLED_INTERVIEWS_FOR_REQUIREMENTS = "cancelled-interviews-for-requirements",
  CANDIDATE_AUTH_V_1 = "candidate-auth-v1",
  CANDIDATE_FEEDBACK_ZEUS = "candidate-feedback-zeus",
  CANDIDATE_FIXED_TOOLBAR = "candidate-fixed-toolbar",
  CANDIDATE_GUIDES_SCHEDULING_BUTTON = "candidate-guides-scheduling-button",
  CANDIDATE_IDENTITY_MAGIC_TOKENS = "candidate-identity-magic-tokens",
  CANDIDATE_IDENTITY_PAGE_VIEWS = "candidate-identity-page-views",
  CANDIDATE_IDENTITY_REPLIES = "candidate-identity-replies",
  CANDIDATE_LOVE_STEP = "candidate-love-step",
  CANDIDATE_NEW_THREADS = "candidate-new-threads",
  CANDIDATE_TIMEZONE = "candidate-timezone",
  CANDIDATES_TAB = "candidates-tab",
  CANDIDATES_TAB_V_2 = "candidates-tab-v-2",
  CC_RECRUITER = "cc-recruiter",
  CHANGE_CREATE_GUIDE_BUTTON_COPY = "change-create-guide-button-copy",
  CHROME_EXTENSION_PUBLISHED_VERSION = "chrome-extension-published-version",
  CLICK_TO_PLACE_EXISTING_EVENT_V_2 = "click-to-place-existing-event-v-2",
  CODESIGNAL_INTEGRATION = "codesignal-integration",
  COLLAPSE_MESSAGE_COMPOSER = "collapse-message-composer",
  COLLECT_REPORTING_REASONS = "collect-reporting-reasons",
  CONSOLIDATED_INTERVIEW_PLAN_EDITOR = "consolidated-interview-plan-editor",
  CONTENT_EDITOR_GIFS = "content-editor-gifs",
  CONTRACTOR_USER_LIST = "contractor-user-list",
  COPY_SETTINGS_FROM_A_JOB = "copy-settings-from-a-job",
  CREATE_A_GUIDE_AND_SHARE_WITH_CANDIDATE = "create-a-guide-and-share-with-candidate",
  CURRENT_STAGE_NEW_EDITOR_FIXES = "current-stage-new-editor-fixes",
  CUSTOMER_TRIAL = "customer-trial",
  DASHBOARD_INBOX = "dashboard-inbox",
  DISABLE_DASHBOARD_GUIDES = "disable-dashboard-guides",
  DISABLE_GUIDE_START = "disable-guide-start",
  DISABLE_INSIGHTS = "disable-insights",
  DISABLE_INVITE = "disable-invite",
  DISABLE_USERS = "disable-users",
  DOMAIN_RESERVATIONS = "domain-reservations",
  DOUBLE_WRITE_JRGTS_FROM_STI = "double-write-jrgts-from-sti",
  DROP_CURRENT_STAGE_BY_HEURISTIC = "drop-current-stage-by-heuristic",
  EDIT_INTERVIEW_IN_MODAL = "edit-interview-in-modal",
  EDIT_INTERVIEW_PLAN_IN_EXTENSION = "edit-interview-plan-in-extension",
  EDIT_INTERVIEWS_BULK_ACTION = "edit-interviews-bulk-action",
  EDIT_STAGES_BULK_ACTION = "edit-stages-bulk-action",
  EDITOR_LINE_ACTIONS = "editor-line-actions",
  EMAIL_EVENT_TRACKING = "email-event-tracking",
  EMAIL_TEMPLATE_JOB_ROLE_NAME_VARIABLE = "email-template-job-role-name-variable",
  EMAIL_THREAD_HEADERS = "email-thread-headers",
  EMBED_AVAILABILITY_IN_GREENHOUSE_SCHEDULER = "embed-availability-in-greenhouse-scheduler",
  EMBED_AVAILABILITY_IN_STAGE_TRACKER = "embed-availability-in-stage-tracker",
  ENABLE_CHAT_ADDON = "enable-chat-addon",
  ENABLE_CONTINUITY_BANNER = "enable-continuity-banner",
  ENABLE_FEEDBACK_ADDON = "enable-feedback-addon",
  ENABLE_GOODTIME_VARIABLE_REPLACE = "enable-goodtime-variable-replace",
  ENABLE_OVERLAY_CALENDARS = "enable-overlay-calendars",
  ENABLE_SCHEDULER_ADDON = "enable-scheduler-addon",
  ENABLE_SOURCING_ADDON = "enable-sourcing-addon",
  ENFORCE_PERMISSIONS = "enforce-permissions",
  EVENT_PRIVACY = "event-privacy",
  EXTENSION_LINK_FLOW = "extension-link-flow",
  EXTENSION_NAVIGATOR = "extension-navigator",
  EXTENSION_UPDATE_BANNER = "extension-update-banner",
  FAN_OUT_JOB_ROLE_GUIDE_TEMPLATE_STAGES_PROPAGATION = "fan-out-job-role-guide-template-stages-propagation",
  FAN_OUT_STAGES_PROPAGATION = "fan-out-stages-propagation",
  FILE_UPLOAD = "file-upload",
  FIX_GUIDE_HIRING_TEAM = "fix-guide-hiring-team",
  FULLSCREEN_EXTENSION_POPOVER_MODAL = "fullscreen-extension-popover-modal",
  GATED_CHAT_LANDING_PAGE = "gated-chat-landing-page",
  GATED_FEEDBACK_LANDING_PAGE = "gated-feedback-landing-page",
  GATED_SOURCING_LANDING_PAGE = "gated-sourcing-landing-page",
  GENERATE_SIP_CONFERENCING_ADDRESS = "generate-sip-conferencing-address",
  GLOBAL_CANDIDATE_AVAILABILITY = "global-candidate-availability",
  GLOBAL_GH_EXTENSION = "global-gh-extension",
  GOOGLE_CALENDAR_ASYNC = "google-calendar-async",
  GREENHOUSE_EMAIL_TEMPLATE_DISPLAY = "greenhouse-email-template-display",
  GREENHOUSE_EMAIL_TEMPLATE_SYNC = "greenhouse-email-template-sync",
  GREENHOUSE_INTRODUCTION_TOUR = "greenhouse-introduction-tour",
  GREENHOUSE_MESSAGE_CTA = "greenhouse-message-cta",
  GUIDE_GENERATION_BY_URL = "guide-generation-by-url",
  GUIDE_GENERATION_BY_URL_FALLBACK = "guide-generation-by-url-fallback",
  GUIDE_HIRING_DECISIONS = "guide-hiring-decisions",
  GUIDE_METRICS_ENABLED = "guide-metrics-enabled",
  GUIDE_PREVIEW_PAGE = "guide-preview-page",
  GUIDE_PROCESS_HACKS = "guide-process-hacks",
  GUIDE_SETTINGS_PAGE = "guide-settings-page",
  GUIDES_FILTER_BY_NAME = "guides-filter-by-name",
  HIDE_INTERVIEWS_BULK_ACTION = "hide-interviews-bulk-action",
  HIDE_ORGANIZATION_SWITCHER = "hide-organization-switcher",
  HIJACK_GREENHOUSE_BUTTONS = "hijack-greenhouse-buttons",
  HIRING_DECISIONS_V_2 = "hiring-decisions-v-2",
  ICS_FILES_IN_MESSAGES = "ics-files-in-messages",
  INCLUDE_ALTERNATIVE_ZOOM_HOSTS_V_2 = "include-alternative-zoom-hosts-v-2",
  INTERVIEW_CONFIRMATIONS_V_2 = "interview-confirmations-v-2",
  INTERVIEW_HASURA_DB_TRIGGER_REPLACEMENT = "interview-hasura-db-trigger-replacement",
  INTERVIEW_PROPAGATION_STRATEGY = "interview-propagation-strategy",
  INTERVIEW_REQUIREMENTS_CARD_V_2 = "interview-requirements-card-v2",
  INTERVIEW_SCHEDULER_ON_GUIDES = "interview-scheduler-on-guides",
  INTERVIEW_TAB_IN_JOBS = "interview-tab-in-jobs",
  INTERVIEW_WHITELISTS = "interview-whitelists",
  INTERVIEWER_CALENDAR_VIEW = "interviewer-calendar-view",
  INTERVIEWER_POOLS_V_2 = "interviewer-pools-v-2",
  INTERVIEWER_TRAINING_V_2 = "interviewer-training-v-2",
  INTERVIEWS_IN_JOB_SETUP_FLOW = "interviews-in-job-setup-flow",
  INTERVIEWS_RICH_BLOCK = "interviews-rich-block",
  INVITE_WITH_GUIDE_POST = "invite-with-guide-post",
  JOB_INTERVIEWS_TAB = "job-interviews-tab",
  JRGTS_ALLOWS_PROPAGATION = "jrgts-allows-propagation",
  LEVER_SANDBOX_CUSTOMER_IDS = "lever-sandbox-customer-ids",
  LINK_GLOBAL_TEMPLATES = "link-global-templates",
  LOCAL_WEBHOOKS_TESTING = "local-webhooks-testing",
  MAINTENANCE_MODE = "maintenance-mode",
  MANAGE_MESSAGE_RECIPIENTS = "manage-message-recipients",
  MANUAL_EMAIL_RENDERING = "manual-email-rendering",
  MERGE_SYNC = "merge-sync",
  MERGESYNC_CANDIDATE_APPLICATION_PROPAGATION = "mergesync-candidate-application-propagation",
  MERGESYNC_CANDIDATE_PROPAGATION = "mergesync-candidate-propagation",
  MERGESYNC_INTERVIEW_PROPAGATION = "mergesync-interview-propagation",
  MERGESYNC_JOB_PROPAGATION = "mergesync-job-propagation",
  MERGESYNC_USER_PROPAGATION = "mergesync-user-propagation",
  MESSAGE_COMPOSER = "message-composer",
  MESSAGE_PRIOR_TO_INVITE = "message-prior-to-invite",
  METRICS_TO_TOP_BAR = "metrics-to-top-bar",
  MIGRATE_BATCH_ETI_DECOUPLING = "migrate-batch-eti-decoupling",
  MIGRATE_BATCH_GUIDE_JOB_ROLE_NAME = "migrate-batch-guide-job-role-name",
  MIGRATE_BATCH_LEGACY_STAGE_SYNC = "migrate-batch-legacy-stage-sync",
  MIGRATE_BATCHSIZE_BACKFILL_GLOBAL_JOB_TEMPLATE = "migrate-batchsize-backfill-global-job-template",
  MIGRATE_BATCHSIZE_BACKFILL_JOB_VERSION = "migrate-batchsize-backfill-job-version",
  MIGRATE_BATCHSIZE_GENERIC = "migrate-batchsize-generic",
  MIGRATE_BATCHSIZE_GUIDE_ORGANIZATION_FK = "migrate-batchsize-guide-organization-fk",
  MIGRATE_BATCHSIZE_LINK_DEFAULT_TEMPLATE = "migrate-batchsize-link-default-template",
  MIGRATE_BATCHSIZE_POINT_OF_CONTACT = "migrate-batchsize-point-of-contact",
  MIGRATE_BATCHSIZE_STAGE_VERSION = "migrate-batchsize-stage-version",
  MINIMAL_EMAIL_NOTIFICATIONS = "minimal-email-notifications",
  MULTIPLE_MESSAGE_TEMPLATES = "multiple-message-templates",
  NEVER_COLLAPSE_MESSENGER_HEADER = "never-collapse-messenger-header",
  NEW_EDITOR_INTERVIEWING_LOADING_ENHANCEMENT = "new-editor-interviewing-loading-enhancement",
  NEW_FEEDBACK_ALGORITHM = "new-feedback-algorithm",
  NEW_FOLLOW_UP_REMINDER_DIALOG_V_2 = "new-follow-up-reminder-dialog-v-2",
  NEW_GUIDE_ARCHITECTURE = "new-guide-architecture",
  NEW_GUIDE_EDITOR = "new-guide-editor",
  NEW_GUIDE_STEPPER = "new-guide-stepper",
  NEW_GUIDES_LIST = "new-guides-list",
  NEW_INTERVIEW_TEMPLATES = "new-interview-templates",
  NEW_JOB_SETUP_FLOW = "new-job-setup-flow",
  NEW_MOVE_TO_STAGE = "new-move-to-stage",
  NEW_SCHEDULER_TASK_DETAILS = "new-scheduler-task-details",
  NOTIFICATION_UPDATES = "notification-updates",
  O_365_SENDING = "o365-sending",
  PERSIST_APOLLO_CACHE = "persist-apollo-cache",
  PERSISTENT_EXTENSION_BUTTON = "persistent-extension-button",
  PITCH_PAGES = "pitch-pages",
  POLL_INTERVAL_CONFIG = "poll-interval-config",
  POOL_SPECIFIC_LOAD_LIMITS = "pool-specific-load-limits",
  POPOUT_STYLE_EXTENSION = "popout-style-extension",
  PREPEND_URLS_TO_ICS_FILES = "prepend-urls-to-ics-files",
  PREVENT_CANDIDATE_PII_STORAGE = "prevent-candidate-pii-storage",
  PRODUCT_TOURS = "product-tours",
  PROPAGATE_GLOBAL_JOB_TEMPLATE = "propagate-global-job-template",
  QA_ASSIST_FEATURES = "qa-assist-features",
  RE_REQUEST_GOOGLE_CALENDAR_SCOPE_V_2 = "re-request-google-calendar-scope-v-2",
  READ_EVENT_DECOUPLING = "read-event-decoupling",
  RECENTLY_USED_TEMPLATES_V_2 = "recently-used-templates-v-2",
  RECRUITER_ACTIVATION_TARGETED_WORKFLOWS = "recruiter-activation-targeted-workflows",
  REGIONS_ENABLED_V_2 = "regions-enabled-v-2",
  REMOVE_EVENT_STAGE_READING = "remove-event-stage-reading",
  REMOVE_LEGACY_GUIDE_POST_SERVICE = "remove-legacy-guide-post-service",
  REMOVE_LEGACY_MESSAGING_DIALOGS = "remove-legacy-messaging-dialogs",
  REMOVE_STAGES_BULK_ACTION = "remove-stages-bulk-action",
  RENAME_NOTIFY_TO_CC = "rename-notify-to-cc",
  REPLACEMENT_DATA_FOR_EVENT_TEMPLATE_INSTALLATIONS = "replacement-data-for-event-template-installations",
  REPLY_VIA_EMAIL = "reply-via-email",
  REPORTING = "reporting",
  REQUEST_AVAILABILITY = "request-availability",
  REQUEST_SCHEDULING_PREFERENCES = "request-scheduling-preferences",
  REQUIRE_CANDIDATE_AUTH_FOR_GUIDE_ACTIONS = "require-candidate-auth-for-guide-actions",
  REQUIRE_CANDIDATE_AUTH_FOR_MESSAGING_V_2 = "require-candidate-auth-for-messaging-v-2",
  RESEND_INVITATIONS = "resend-invitations",
  RESTORE_JOB_ROLE_GUIDE_TEMPLATE_CHANGES = "restore-job-role-guide-template-changes",
  SAVE_TEMPLATE_ON_SEND_PROMPT = "save-template-on-send-prompt",
  SCHEDULED_INTERVIEW_CARD_AND_DETAILS_CLEANUP = "scheduled-interview-card-and-details-cleanup",
  SCHEDULED_INTERVIEW_CARD_V_2 = "scheduled-interview-card-v2",
  SCHEDULED_INTERVIEW_INTEGRATION_STATUS_V_2 = "scheduled-interview-integration-status-v-2",
  SCHEDULED_INTERVIEWS_PAGE_V_2 = "scheduled-interviews-page-v-2",
  SCHEDULER_AVAILABILITIES_IN_V_2 = "scheduler-availabilities-in-v-2",
  SCHEDULER_DIALOG_V_2 = "scheduler-dialog-v2",
  SCHEDULER_DROPDOWNS_V_2 = "scheduler-dropdowns-v-2",
  SCHEDULER_REVIEW_PAGE_V_2 = "scheduler-review-page-v2",
  SCHEDULER_V_2 = "scheduler-v-2",
  SCHEDULING_ALGORITHM_VERSION_V_2 = "scheduling-algorithm-version-v-2",
  SCHEDULING_ALGORITHM_WEIGHTS_V_2 = "scheduling-algorithm-weights-v-2",
  SCHEDULING_REQUEST_TEMPLATE_CLEANUP = "scheduling-request-template-cleanup",
  SCHEDULING_TASK_COMMENTS = "scheduling-task-comments",
  SCHEDULING_TASK_NAVIGATOR = "scheduling-task-navigator",
  SELF_SCHEDULING = "self-scheduling",
  SELF_SERVE_DOMAIN_RESERVATIONS = "self-serve-domain-reservations",
  SEND_CONFIRMATION_DIALOG_V_2 = "send-confirmation-dialog-v2",
  SHOW_EXTENSION_OMNIBUTTON = "show-extension-omnibutton",
  SHOW_FOLLOWERS_IN_EMAIL_FOOTER = "show-followers-in-email-footer",
  SHOW_GLOBAL_JOB_TEMPLATES = "show-global-job-templates",
  SLACK_SALES_NOTIFICATIONS = "slack-sales-notifications",
  STAGE_HASURA_DB_TRIGGER_REPLACEMENT = "stage-hasura-db-trigger-replacement",
  STANDARDIZE_INTERVIEW_EDITING = "standardize-interview-editing",
  SYNC_ATS_JOBS = "sync-ats-jobs",
  SYNC_SLACK_BACKGROUND = "sync-slack-background",
  TIMEZONES_FROM_V_1 = "timezones-from-v-1",
  TRUST_SCORE_SURVEY = "trust-score-survey",
  UPDATER_AGENCY_LIST = "updater-agency-list",
  USE_DATALOADERS = "use-dataloaders",
  USE_EXTERNAL_INTERVIEWER_ALGORITHM_V_2 = "use-external-interviewer-algorithm-v-2",
  USE_GUIDE_TEMPLATES = "use-guide-templates",
  USE_RECIPIENTS_FROM_CLIENT = "use-recipients-from-client",
  USE_SEND_GUIDE_EMAIL_ON_SUBMIT_REFACTOR = "use-send-guide-email-on-submit-refactor",
  USE_TEMPLATE_FOR_AVAILABILITY_REQUEST_FOLLOWUP = "use-template-for-availability-request-followup",
  USE_V_2_SCHEDULING_ALGORITHM_V_2 = "use-v-2-scheduling-algorithm-v-2",
  V_1_SYSTEM_BANNER = "v-1-system-banner",
  V_2_POST_TEMPLATES_TABLE = "v-2-post-templates-table",
  V_2_INSIGHTS_DASHBOARDS = "v2-insights-dashboards",
  VIEW_GOOGLE_DIFFERENCES = "view-google-differences",
  VIEW_INTERVIEW_TEMPLATE_INSTALLATIONS_LIST = "view-interview-template-installations-list",
  WATCH_GOOGLE_USER_DEACTIVATION = "watch-google-user-deactivation",
  ZEUS = "zeus",
  ZEUS_DELETE_POST = "zeus-delete-post",
  ZEUS_FIXED_TOOLBAR = "zeus-fixed-toolbar",
  ZEUS_POST_WITHOUT_NOTIFYING = "zeus-post-without-notifying",
  ZEUS_PREVIEW_EMAILS = "zeus-preview-emails",
  ZEUS_RENDER_EMAIL_CONTENT = "zeus-render-email-content",
  ZEUS_RENDER_PLAINTEXT_CONTENT = "zeus-render-plaintext-content",
  ZEUS_SHARE_A_GUIDE_WITH_A_CANDIDATE = "zeus-share-a-guide-with-a-candidate",
  ZEUS_TRANSITION = "zeus-transition",
}

export default FeatureFlags;
