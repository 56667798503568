import { httpBatchLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { ssrPrepass } from "@trpc/next/ssrPrepass";
import { inferReactQueryProcedureOptions } from "@trpc/react-query/dist/utils/inferReactQueryProcedure";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

import type { AppRouter } from "../server/trpc/router";

function getBaseUrl() {
  if (typeof window !== "undefined") return "";

  if (process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT) {
    return process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT;
  }

  return `https://localhost:3000`;
}

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      links: [
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
      queryClientConfig: {
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
      },
    };
  },
  ssrPrepass,
  ssr: true,
});
