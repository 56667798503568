import { OrganizationFeaturesEnum } from "generated/graphql-codegen/graphql";

export enum OrganizationFeatures {
  MESSAGING = "MESSAGING",
  SCHEDULING = "SCHEDULING",
  SURVEYS = "SURVEYS",
}

type OrganizationToGetFeatures = {
  id: string;
  messagingEnabled: boolean;
  schedulingEnabled: boolean;
  surveysEnabled: boolean;
};

export const getOrganizationFeatures = (
  organization: OrganizationToGetFeatures
) => {
  return [
    ...(organization.messagingEnabled ? [OrganizationFeatures.MESSAGING] : []),
    ...(organization.schedulingEnabled
      ? [OrganizationFeatures.SCHEDULING]
      : []),
    ...(organization.surveysEnabled ? [OrganizationFeatures.SURVEYS] : []),
  ];
};

export const featureIsEnabledForOrg = (
  features: OrganizationFeaturesEnum | OrganizationFeaturesEnum[],
  organization: {
    features: OrganizationFeaturesEnum[];
  }
) => {
  const orgFeatures = organization.features;

  return [features].flat().every((f) => orgFeatures.includes(f));
};
