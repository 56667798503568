import * as Sentry from "@sentry/nextjs";
import { useAuthContext } from "auth/context";
import { gql } from "generated/graphql-codegen";
import {
  CandidateForGuideAuthFragment,
  OrganizationFeaturesEnum,
  UserForAuthContextFragment,
} from "generated/graphql-codegen/graphql";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { GuidePermission } from "shared/auth/guide-permissions";
import { featureIsEnabledForOrg } from "shared/utils/features";
import useQuery from "utils/useQuery";

type AuthUser = UserForAuthContextFragment;

gql(`
  fragment CandidateForGuideAuth on Candidate {
    id
    email
    timezone
    organization {
      id
      name
      features
    }
  }
`);

const GUIDE_FOR_AUTH = gql(`
query GuideForAuth($organizationSlug: String!, $shortId: String!) {
    guideByShortId(organizationSlug: $organizationSlug, shortId: $shortId) {
      id
      candidate {
        ...CandidateForGuideAuth,
      }
      candidateUserMembershipId
      currentUserPermissions
    }
  }
`);

type GuideAuthContextValue = {
  loading: boolean;
  user?: AuthUser;
  hasPostWritePermissions: (createdById: string) => boolean;
  isPostOwner: (createdById: string) => boolean;
  isGuideCandidate: boolean;
  isOrgUser: boolean;
  hasPermission: (permission: GuidePermission) => boolean;
  hasFeatureEnabled: (
    features: OrganizationFeaturesEnum | OrganizationFeaturesEnum[]
  ) => boolean;
  candidate: CandidateForGuideAuthFragment | undefined;
  guideId: string | undefined;
};

const GuideAuthContext = React.createContext<GuideAuthContextValue | undefined>(
  undefined
);

export const useGuideAuthContext = () => {
  const context = useContext(GuideAuthContext);
  const authContext = useAuthContext();
  if (context === undefined) {
    // TODO: This could be an internal user in the extension, or a candidate
    // on a booking link. Only rely on these permissions within the Guide.
    return {
      loading: authContext.loading,
      user: authContext.user,
      hasPostWritePermissions: () => true,
      isPostOwner: () => true,
      isGuideCandidate: false,
      isOrgUser: true,
      hasFeatureEnabled: () => false,
      hasPermission: () => false,
      candidate: undefined,
      guideId: undefined,
    };
  }
  return context;
};

type ProviderProps = {
  organizationSlug: string;
  shortId: string;
  children: React.ReactNode;
};
export function GuideAuthContextProvider({
  organizationSlug,
  shortId,
  children,
}: ProviderProps) {
  const { loading: loadingUser, user } = useAuthContext();
  const { data: guideData, loading: loadingGuide } = useQuery(GUIDE_FOR_AUTH, {
    variables: {
      organizationSlug,
      shortId,
    },
  });

  const loading = loadingUser || loadingGuide;

  const guideCandidateUserMembershipId =
    guideData?.guideByShortId?.candidateUserMembershipId;
  const candidate = guideData?.guideByShortId?.candidate;
  const candidateId = candidate?.id;
  const guideId = guideData?.guideByShortId?.id;
  const guideOrgId = guideData?.guideByShortId?.candidate.organization.id;

  const permissions = guideData?.guideByShortId?.currentUserPermissions;
  const hasPermission = useCallback(
    (permission: GuidePermission) =>
      (permissions || []).includes(permission as string),
    [permissions]
  );

  // isOrgUser means that this user is acting on behalf of the org for this Guide;
  // allows them to edit the guide, see Candidate Unreads, etc.
  // See userHasWritePermissionsForGuide in server/auth/guide-permissions.
  const isOrgUser =
    !!user?.currentOrganization &&
    user?.currentOrganization.id === guideOrgId &&
    hasPermission(GuidePermission.WRITE);

  // TODO: As much as possible of this should be done by the backend and the frontend just display features based on the response.

  // Whether the user should be considered the candidate
  const isGuideCandidate = useMemo(() => {
    if (loading) {
      return false;
    }

    return hasPermission(GuidePermission.MARK_READ);
  }, [hasPermission, loading]);

  /* Determines the "post owner" as in, who can edit/delete the post.
     See userOwnsPost() in server/auth/guide-post-permissions.
  */
  const isPostOwner = useCallback(
    (createdById?: string) => {
      if (loading) {
        return false;
      }

      // Note: for legacy posts createdById is faked to the candidate id
      const postIsByCandidate =
        !createdById ||
        createdById === guideCandidateUserMembershipId ||
        createdById === candidateId;

      if (postIsByCandidate && isGuideCandidate) {
        return true;
      }
      if (!postIsByCandidate && isOrgUser) {
        return true;
      }
      return false;
    },
    [
      guideCandidateUserMembershipId,
      candidateId,
      isGuideCandidate,
      isOrgUser,
      loading,
    ]
  );

  // Whether the user has permissions to edit or delete a post based on the creator
  const hasPostWritePermissions = useCallback(
    (createdById?: string) => {
      if (loading) {
        return false;
      }

      return (
        isPostOwner(createdById) && hasPermission(GuidePermission.POST.EDIT)
      );
    },
    [loading, isPostOwner, hasPermission]
  );

  function hasFeatureEnabled(
    features: OrganizationFeaturesEnum | OrganizationFeaturesEnum[]
  ) {
    if (!guideData?.guideByShortId?.candidate.organization) return false;

    return featureIsEnabledForOrg(
      features,
      guideData?.guideByShortId?.candidate.organization
    );
  }

  useEffect(() => {
    Sentry.setTags({
      isGuideCandidate,
    });
  }, [isGuideCandidate]);

  const value = {
    loading,
    user,
    hasPostWritePermissions,
    isGuideCandidate,
    isOrgUser,
    isPostOwner,
    hasFeatureEnabled,
    hasPermission,
    candidate,
    guideId,
  };

  return (
    <GuideAuthContext.Provider value={value}>
      {children}
    </GuideAuthContext.Provider>
  );
}
